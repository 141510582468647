<template>
  <not-authorised>
    <template #left-half>
      <forgort-password-form class="forgot-form"/>
    </template>
    <template #right-half>
      <register-link />
    </template>
  </not-authorised>
</template>

<script>
import ForgortPasswordForm from '../components/common/ForgortPasswordForm.vue'
import RegisterLink from '../components/common/RegisterLink.vue'
import NotAuthorised from '../components/common/NotAuthorised.vue'
import UserAddIcon from '../components/icons/UserAddIcon.vue'
export default {
  components: { NotAuthorised, UserAddIcon, ForgortPasswordForm, RegisterLink },
  name: 'ForgotPassword'
}
</script>

<style lang="scss" scoped>
.forgot-form {
  width: 50%;
}
</style>